import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, FormGroup, Label, Input, Button } from "reactstrap";
import useInput from "./use-input";

type Props = {
    children(onOpen: () => void): React.ReactNode;
};

function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

// contact: c9c2b1f4-acb2-4890-9b74-37f80c488204

const RequestADemo = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [firstName, firstNameInput] = useInput("demoFirstName", "First Name *");
    const [lastName, lastNameInput] = useInput("demoLastName", "Last Name *");
    const [phoneNumber, phoneNumberInput] = useInput("demoPhoneNumber", "Phone Number", "tel");
    const [email, emailInput] = useInput("demoEmail", "Email *", "email");
    // const [message, messageInput] = useInput("demoMessage", "Message", "textarea");
    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState("");

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (isBusy) {
            return;
        }

        setIsBusy(true);

        try {
            const result = await fetch(
                "https://api.hsforms.com/submissions/v3/integration/submit/4831651/ae55e127-6dce-4c47-9ba4-6afb5cd781f4",
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        "fields": [
                            {
                                "name": "email",
                                "value": email,
                            },
                            {
                                "name": "firstname",
                                "value": firstName,
                            },
                            {
                                "name": "lastname",
                                "value": lastName,
                            },
                            {
                                "name": "phone",
                                "value": phoneNumber,
                            },
                        ],
                        "context": {
                            "hutk": getCookie("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                            "pageUri": window.location.href,
                            "pageName": document.title,
                        },
                        // "legalConsentOptions":{ // Include this object when GDPR options are enabled
                        //   "consent":{
                        //     "consentToProcess":true,
                        //     "text":"I agree to allow Example Company to store and process my personal data.",
                        //     "communications":[
                        //       {
                        //         "value":true,
                        //         "subscriptionTypeId":999,
                        //         "text":"I agree to receive marketing communications from Example Company."
                        //       }
                        //     ]
                        //   }
                        // }
                    }),
                });

            if (result.ok) {
                closeModal();
            }
            else {
                const json = await result.json();
                setError("There was an error submitting the form: " + json.message);
            }
        }
        catch (e) {
            setError("There was an error submitting the form. Please check your internet connectivitiy");
        }
        finally {
            setIsBusy(false);
        }
    }

    return (
        <>
            {props.children(openModal)}

            <Modal isOpen={isOpen} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>
                    Request a Demo
                </ModalHeader>
                <ModalBody>
                    <span className="text-danger">
                        {error}
                    </span>
                    <form onSubmit={onSubmit}>
                        {firstNameInput}
                        {lastNameInput}
                        {phoneNumberInput}
                        {emailInput}

                        <Button type="submit" disabled={isBusy}>
                            {
                                isBusy
                                    ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    : "Submit"
                            }
                        </Button>
                    </form>
                </ModalBody>
            </Modal>
        </>
    );
}

export default RequestADemo;
