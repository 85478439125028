import React, { useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";

const useInput = (name: string, title: string, type?: InputType) => {
    const [value, setValue] = useState("");

    const input = (
        <FormGroup>
            <Label for={name}>{title}</Label>
            <Input type={type} name={name} id={name} value={value} onChange={e => setValue(e.target.value)} />
        </FormGroup>
    );

    return [value, input];
}

export default useInput;
