import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "gatsby";

import logo from "../images/drive_revenue_svg-02.svg";

import envelope from "../images/drive_revenue_svg-14.svg";
import support from "../images/drive_revenue_svg-13.svg";
import linkedin from "../images/drive_revenue_svg-15.svg";
import facebook from "../images/drive_revenue_facebook-21.svg";
import twitter from "../images/drive_revenue_svg_twitter-22.svg";

const Footer = () => (
    <Container fluid style={{ background: "#151445" }} className="pt-5 footer">
        <Row className="justify-content-center text-primary">
            <Col md={3}>
                <img src={logo} style={{ height: 40 }} />
                <p className="pt-4 pr-4">
                    Drive Revenue – an online accounting and practice management platform to transform your legal practice.
                </p>
            </Col>
            <Col md={3}>
                <h5 className="pb-4">
                    SITE MAP
                </h5>

                <ul className="list-unstyled text-uppercase">
                    <li>
                        <Link to="/">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/our-solutions">
                            Our Solutions
                        </Link>
                    </li>
                    <li>
                        <Link to="/support">
                            Support
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog">
                            Blog
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact-us">
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </Col>
            <Col md={3}>
                <h5 className="pb-4">
                    HEADQUARTERS
                </h5>

                <ul className="list-unstyled">
                    <li>
                        <img src={envelope} />

                        <a href="mailto:info@driverevenue.net">
                            info@driverevenue.net
                        </a>
                    </li>
                    <li>
                        <img src={support} />

                        <a href="tel:+27 21 286 0735">
                            +27 21 286 0735
                        </a>
                    </li>
                    <li>
                        <img src={linkedin} />

                        <a href="https://www.linkedin.com/company/drive-revenue/">
                            Drive Revenue
                        </a>
                    </li>
                    <li>
                        <img src={facebook} />

                        <a href="https://www.facebook.com/driverevenue/">
                            Facebook
                        </a>
                    </li>
                    <li>
                        <img src={twitter} />

                        <a href="https://twitter.com/Drive_Revenue">
                            Twitter
                        </a>
                    </li>
                </ul>
            </Col>
        </Row>

        <Row className="justify-content-center text-primary pt-3 pb-3" style={{ background: "#0F0F34" }}>
            <Col md={9}>
                <small>
                    © 2020 Drive Revenue. All Rights Reserved.
                    &nbsp;
                    •
                    &nbsp;
                    <Link to="/privacy-policy" >Privacy Policy</Link
                    >&nbsp;
                    •
                    &nbsp;
                    <Link to="/terms-of-service">Terms of Service</Link>
                </small>
            </Col>
        </Row>
    </Container>
);

export default Footer;
