import React, { useState } from "react";
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, Button } from "reactstrap";
import { Link } from "gatsby";

import logo from "../images/drive_revenue_logo-c.png";
import RequestADemo from "./request-a-demo";

const Header = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <Navbar color="white" light fixed="top" className="shadow text-uppercase font-weight-bold" expand="lg">
      <NavbarBrand href="/" className="mr-auto">
        <img src={logo} style={{ height: 100 }} />
      </NavbarBrand>

      <NavbarToggler onClick={toggleNavbar} className="mr-2" />

      <Collapse isOpen={!collapsed} navbar>
        <Nav navbar className="ml-auto">
          <NavItem>
            <NavLink to="/" activeClassName="active" tag={Link}>
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/our-solutions" activeClassName="active" tag={Link}>
              Our Solutions
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/support" activeClassName="active" tag={Link}>
              Support
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/blog" activeClassName="active" tag={Link}>
              Blog
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/contact-us" activeClassName="active" tag={Link}>
              Contact Us
            </NavLink>
          </NavItem>
          <NavItem className="pl-5">
            <RequestADemo>
              {onclick =>
                <Button color="secondary" className="pl-4 pr-4 rounded-pill text-uppercase font-weight-bold" onClick={onclick}>
                  Request a Demo
                </Button>
              }
            </RequestADemo>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default Header
